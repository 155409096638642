<template>
  <div class="createWork">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div
        v-for="(item, index) in listData"
        :key="index"
        class="oItem"
        @click="toDetail(item)"
      >
        <div class="item">
          <div class="oItem">
            <div class="item-t">姓名:</div>
            <div class="item-b">{{ item.userName }}</div>
          </div>

          <div class="oItem">
            <div class="item-t">手机号:</div>
            <div class="item-b">{{ item.mobile }}</div>
          </div>

          <div class="oItem">
            <div class="item-t">申请时间:</div>
            <div class="item-b">{{ item.inTime }}</div>
          </div>
          <div class="oItem">
            <div class="item-t">公司:</div>
            <div class="item-b">{{ item.companyName }}</div>
          </div>
          <div class="oItem te">
            <div class="item-t">申请的房号:</div>
            <div class="item-b">{{ item.spaceName }}</div>
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import CountTo from "vue-count-to";
import { createWorkUrl } from "./api.js";
var moment = require("moment");
export default {
  name: "createWork",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      form: {
        donatePic: "",
        donateContent: "",
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  mounted() {},
  methods: {
    onLoad() {
      this.getCreateWork();
    },
    toList() {
      this.$router.push({
        name: "pointsList",
      });
    },

    getCreateWork() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        openId: this.openId,
      };
      this.$axios
        .post(`${createWorkUrl}`, params, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.createWork {
  min-height: 100vh;
  background: #f5f5f5;
  color: #666;
  box-sizing: border-box;
  .item {
    padding: 10px 40px;
    background: #fff;
    margin-bottom: 10px;
    font-size: 32px;
    .oItem {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }
  }
  .te {
    color: #009aff;
  }
}
</style>
<style lang="less">
.createWork {
}
</style>
